import Head from 'next/head'

const DEFAULT_TITLE = 'AI-powered Integration Framework - Integration.app'
const DEFAULT_DESCRIPTION =
  "Integration platforms don't work. Scale your in-house integration development with our AI-powered framework."
const DEFAULT_OPEN_GRAPH_IMAGE = getAbsoluteUrl(
  'images/image-opengraph-202403.png',
)

export function HeadMetatags({
  title = DEFAULT_TITLE,
  description = DEFAULT_DESCRIPTION,
  openGraphImageUrl,
  type = 'website',
  children,
}: {
  title?: string
  description?: string
  openGraphImageUrl?: string
  type?: 'website' | 'article'
  children?: React.ReactNode
}) {
  const openGraphImage = openGraphImageUrl || DEFAULT_OPEN_GRAPH_IMAGE
  const pageTitle = `${title} - Integration.app`

  return (
    <Head>
      <title>{pageTitle}</title>
      <meta name='description' content={description} />
      <meta property='og:type' content={type} key={'openGraphType'} />
      <meta property='og:title' content={title} key={'openGraphTitle'} />
      <meta
        property='og:description'
        content={description}
        key={'openGraphDescription'}
      />
      <meta
        property='og:image'
        content={openGraphImage}
        key={'openGraphImage'}
      />
      <meta
        name='twitter:card'
        content='summary_large_image'
        key={'twitterCard'}
      />
      <meta
        property='twitter:domain'
        content='integration.app'
        key={'twitterDomain'}
      />
      <meta name='twitter:title' content={title} key={'twitterTitle'} />
      <meta
        name='twitter:description'
        content={description}
        key={'twitterDescription'}
      />
      <meta
        name='twitter:image'
        content={openGraphImage}
        key={'twitterImage'}
      />
      {children}
    </Head>
  )
}

export function NoRobotsMetatag() {
  return (
    <Head>
      <meta name='robots' content='noindex,nofollow' />
    </Head>
  )
}

export function HeadMetatagViewport() {
  return (
    <meta
      name='viewport'
      content='minimum-scale=1, initial-scale=1, width=device-width'
    />
  )
}

export function HeadMetatagsFavicons() {
  return (
    <>
      <link rel='icon' type='image/svg+xml' href='/favicon/favicon.svg' />
      <link
        rel='icon'
        type='image/png'
        sizes='32x32'
        href='/favicon/favicon-32x32.png'
      />
      <link
        rel='icon'
        type='image/png'
        sizes='16x16'
        href='/favicon/favicon-16x16.png'
      />
    </>
  )
}

export function HeadMetatagsPreloadFonts() {
  const fonts = [
    '/fonts/logo.ttf',
    '/fonts/clash-grotesk-400.woff2',
    '/fonts/clash-grotesk-500.woff2',
    '/fonts/satoshi-400.woff2',
    '/fonts/satoshi-500.woff2',
  ]
  return (
    <>
      {fonts.map((font) => (
        <link
          rel='preload'
          href={font}
          as='font'
          type='font/woff2'
          crossOrigin='anonymous'
          key={font}
        />
      ))}
    </>
  )
}

function getAbsoluteUrl(url: string) {
  const isDevelopment = process.env.NODE_ENV === 'development'
  const origin = isDevelopment
    ? 'http://localhost:8000/'
    : 'https://integration.app/'
  return `${origin}${url}`
}
