import { AppProps } from 'next/app'
import Head from 'next/head'

import * as Sentry from '@sentry/react'

import { GTMProvider } from '@elgorditosalsero/react-gtm-hook'
import { HeadMetatags } from 'routes/components/HeadMetatags'

import 'styles/globals.css'
import 'styles/website.css'
import 'styles-scss/index.scss'
import { TrackingContextProvider } from 'routes/components/Tracking/tracking-context'

if (process.env.) {
  Sentry.init({
    dsn: 'https://ab68abc0f6663746bafb1e2b1d82e33c@o956397.ingest.sentry.io/4506388004864000',
  })
}

export default function IntegrationConsoleApp({
  Component,
  pageProps,
}: AppProps) {
  const HeadContent = () => (
    <>
      <Head>
        {process.env. && (
          <>
            <script type='text/javascript' src='/tracking.js' />
          </>
        )}
      </Head>
      <HeadMetatags
        title='AI-powered Integration Framework - Integration.app'
        description={
          "Integration platforms don't work. Scale your in-house integration development with our AI-powered framework."
        }
      />
    </>
  )

  return (
    <>
      <TrackingContextProvider>
        <HeadContent />
        {process.env. ? (
          <GTMProvider state={{ id: 'GTM-TTWK8G4' }}>
            <Component {...pageProps} />
          </GTMProvider>
        ) : (
          <Component {...pageProps} />
        )}
      </TrackingContextProvider>
    </>
  )
}
